<template>
  <div>
    <v-btn
      dark
      small
      ripple
      elevation="7"
      color="secondary"
      @click="goBack"
    >
      <v-icon left>
        mdi-arrow-left-thin
      </v-icon> Back
    </v-btn>

    <v-card-title class="font-weight-bold pl-0 pb-0 pr-0">
      Predicted Disease
    </v-card-title>

    <v-container
      class="pr-0 pb-0 pt-0"
      fluid
    >
      <v-row>
        <v-col
          class="d-flex align-center pl-0"
          cols="6"
        >
          <span class="font-size-15">
            <v-icon name="alert-icon">mdi-alert-circle-outline</v-icon> {{ disease.diseaseEn }}
          </span>
        </v-col>
        <v-col
          class="d-flex flex-column"
          cols="6"
        >
          <v-btn
            dark
            small
            ripple
            style="height: 50%; width: 50%; padding: 10px 5px;"
            elevation="7"
            color="orange mb-3"
            @click="prescriptionPage"
          >
            <v-icon left>
              mdi-note-edit-outline
            </v-icon> Rx With Disease
          </v-btn>

          <v-btn
            dark
            small
            ripple
            style="height: 50%; width: 50%; padding: 10px 5px;"
            elevation="7"
            color="orange darken-3"
            @click="prescriptionPage"
          >
            <v-icon left>
              mdi-notebook-edit
            </v-icon> Rx W/O Disease
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="6">
          <v-card-title class="font-weight-bold pl-0">
            Investigations
          </v-card-title>
          <Multiselect
            v-model="additionalInvestigations"
            :options="allInvestigationsFiltered"
            :searchable="true"
            :close-on-select="true"
            :hide-selected="true"
            :show-labels="false"
            :multiple="true"
            :clear-on-select="false"
            :preserve-search="true"
            :options-limit="20"
            placeholder="Search investigations"
            label="investigationEn"
            track-by="id"
            @select="setSelectedInvestigationValue"
          >
            <template
              slot="selection"
              slot-scope="{ values, search, isOpen }"
            >
              <span
                v-if="values.length && !isOpen"
                class="multiselect__single"
              >
                {{ values.length > 1 ? values.length + ' investigations' : values.length + ' investigation' }} selected
              </span>
            </template>
          </Multiselect>
          <div class="investigation-options">
            <v-checkbox
              v-for="(investigation, index) in $store.state.investigationStore.investigationsForDisease"
              :key="`investigation-list-${index}`"
              v-model="selectedInvestigationIds"
              class="ma-0"
              :label="investigation.investigationEn"
              :value="investigation.investigationId"
              hide-details="auto"
              color="orange"
              @change="onChangeInvestigation(investigation)"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <v-card-title class="font-weight-bold pl-0">
            Treatments
          </v-card-title>
          <Multiselect
            v-model="additionalTreatments"
            :options="allTreatmentsFiltered"
            :searchable="true"
            :close-on-select="true"
            :hide-selected="true"
            :show-labels="false"
            :multiple="true"
            :clear-on-select="false"
            :preserve-search="true"
            :options-limit="20"
            placeholder="Search treatments"
            label="treatmentEn"
            track-by="id"
            @select="setSelectedTreatmentValue"
          >
            <template
              slot="selection"
              slot-scope="{ values, search, isOpen }"
            >
              <span
                v-if="values.length && !isOpen"
                class="multiselect__single"
              >
                {{ values.length > 1 ? values.length + ' treatments' : values.length + ' treatment' }} selected
              </span>
            </template>
          </Multiselect>
          <div class="treatment-options">
            <v-checkbox
              v-for="(treatment, index) in $store.state.treatmentStore.treatmentsForDisease"
              :key="`treatment-list-${index}`"
              v-model="selectedTreatmentIds"
              class="ma-0"
              :label="treatment.treatmentEn"
              :value="treatment.treatmentId"
              hide-details="auto"
              color="orange"
              @change="onChangeTreatment(treatment)"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {empty, arrDiff, arrayUnique} from "@/helpers/utility";
import Multiselect from "vue-multiselect";

export default {
    name: "InvestigationComponent",
    components: {Multiselect},
    data() {
        return {
            disease: '',
            additionalTreatments: [],
            additionalInvestigations: [],
            investigationItem: [],
            treatmentItem: [],
            investigations: '',
            treatments: '',
            selectedInvestigationIds: [],
            selectedTreatmentIds: [],
            prescriptionInvestigations: [],
            prescriptionTreatments: [],
        }
    },
    computed: {
        allInvestigationsFiltered() {
            let allInvestigationIds = Object.values(this.$store.state.investigationStore.allInvestigations).map((item) => item.id);
            let diseaseWiseInvestigationIds = Object.values(this.$store.state.investigationStore.investigationsForDisease).map((item) => item.investigationId);
            let diffArray = arrDiff(allInvestigationIds, diseaseWiseInvestigationIds);
            if (!empty(diffArray)) {
                diffArray = diffArray.filter(arrayUnique);
                return Object.values(this.$store.state.investigationStore.allInvestigations).filter((item) => diffArray.includes(item.id));
            }

            return [];
        },
        allTreatmentsFiltered() {
            let allTreatmentIds = Object.values(this.$store.state.treatmentStore.allTreatments).map((item) => item.id);
            let diseaseWiseTreatmentIds = Object.values(this.$store.state.treatmentStore.treatmentsForDisease).map((item) => item.treatmentId);
            let diffArray = arrDiff(allTreatmentIds, diseaseWiseTreatmentIds); // For [1,2,3] [2,3] it will yield [1].
            if (!empty(diffArray)) {
                diffArray = diffArray.filter(arrayUnique);
                return Object.values(this.$store.state.treatmentStore.allTreatments).filter((item) => diffArray.includes(item.id));
            }

            return [];
        },
    },
    created() {
        if (empty(this.$store.state.symptomsStore.selectedDisease)) {
            this.$store.commit('symptomsStore/SET_SELECTED_DISEASE', this.$cookies.get('SELECTED_DISEASE'));
        }
        if (empty(this.$store.state.investigationStore.selectedInvestigations)) {
            this.$store.commit('investigationStore/SET_SELECTED_INVESTIGATIONS', JSON.parse(this.$cookies.get('SELECTED_INVESTIGATIONS')));
            this.$store.state.investigationStore.selectedInvestigations?.map(item => {
                this.selectedInvestigationIds.push(item.investigationId);
            })
        }
        if (empty(this.$store.state.treatmentStore.selectedTreatments)) {
            this.$store.commit('treatmentStore/SET_SELECTED_TREATMENTS', JSON.parse(this.$cookies.get('SELECTED_TREATMENTS')));
            this.$store.state.treatmentStore.selectedTreatments?.map(item => {
                this.selectedTreatmentIds.push(item.treatmentId);
            })
        }
        this.disease = this.$store.state.symptomsStore.selectedDisease;
        this.getInvestigations();
        this.getTreatments();
        this.getInvestigations(this.disease.diseaseId);
        this.getTreatments(this.disease.diseaseId);
    },
    methods: {
        async getInvestigations(id = null) {
            await this.$store.dispatch("investigationStore/list", {id});
        },
        async getTreatments(id = null) {
            await this.$store.dispatch('treatmentStore/list', {id});
        },
        goBack() {
            let current = this.$route;
            let route = {
                name: current.name,
                params: current.params,
                query: {step: 1}
            };
            this.$parent.step--;
            this.$router.push(route);
        },
        prescriptionPage() {
            let current = this.$route;
            let route = {
                name: current.name,
                params: current.params,
                query: {step: 3}
            };
            this.$parent.step = 3;
            // this.updateAppointment();
            this.$router.push(route);
        },
        onChangeInvestigation(data) {
            let obj = this.prescriptionInvestigations.find((item, key) => {
                if (item.investigationId === data.investigationId) {
                    this.prescriptionInvestigations.splice(key, 1);
                    this.removeSelectedInvestigation(data);
                    return true; // stop searching
                }
            });

            if (!obj) {
                this.prescriptionInvestigations.push(data);
                this.$cookies.set('SELECTED_INVESTIGATIONS', JSON.stringify(this.prescriptionInvestigations));
                this.$store.commit('investigationStore/SET_SELECTED_INVESTIGATIONS', this.prescriptionInvestigations);
            }
        },
        onChangeTreatment(data) {
            let obj = this.prescriptionTreatments.find((item, key) => {
                if (item.treatmentId === data.treatmentId) {
                    this.prescriptionTreatments.splice(key, 1);
                    this.removeSelectedTreatment(data);
                    return true; // stop searching
                }
            });

            if (!obj) {
                this.prescriptionTreatments.push(data);
                this.$cookies.set('SELECTED_TREATMENTS', JSON.stringify(this.prescriptionTreatments));
                this.$store.commit('treatmentStore/SET_SELECTED_TREATMENTS', this.prescriptionTreatments);
            }
        },
        removeSelectedTreatment(removedOption) {
            let key = this.treatmentItem.indexOf(removedOption.treatmentId);
            if (key >= 0) {
                this.treatmentItem.splice(key, 1);
            }
        },
        insertOrUpdateTreatment(selectedOption) {
            let obj = this.$store.state.treatmentStore.treatmentsForDisease.find((obj, key) => {
                if (obj.id === selectedOption.id) {
                    this.$store.commit('treatmentStore/REMOVE_ONE_TREATMENT', key);
                    this.treatments.splice(key, 1);
                    return true; // stop searching
                }
            });
            if (!obj) {
                this.treatments.push(selectedOption);
                this.$store.commit('treatmentStore/SET_ONE_TREATMENT', selectedOption);
            }
        },
        setSelectedTreatmentValue(selectedOption) {
            this.treatmentItem.push(selectedOption.id);
            this.insertOrUpdateTreatment(selectedOption);
            this.onChangeTreatment({treatmentId: selectedOption.id, treatmentEn: selectedOption.treatmentEn});
        },
        removeSelectedInvestigation(removedOption) {
            let key = this.investigationItem.indexOf(removedOption.investigationId);
            if (key >= 0) {
                this.investigationItem.splice(key, 1);
            }
        },
        insertOrUpdateInvestigation(selectedOption) {
            let obj = this.$store.state.investigationStore.investigationsForDisease.find((obj, key) => {
                if (obj.id === selectedOption.id) {
                    this.$store.commit('investigationStore/REMOVE_ONE_INVESTIGATION', key);
                    this.investigations.splice(key, 1);
                    return true; // stop searching
                }
            });
            if (!obj) {
                this.investigations.push(selectedOption);
                this.$store.commit('investigationStore/SET_ONE_INVESTIGATION', selectedOption);
            }
        },
        setSelectedInvestigationValue(selectedOption) {
            this.investigationItem.push(selectedOption.id);
            this.insertOrUpdateInvestigation(selectedOption);
            this.onChangeInvestigation({investigationId: selectedOption.id, investigationEn: selectedOption.investigationEn});
        },
        async updateAppointment() {
            try {
                let data = {
                    treatments: [],
                    investigations: []
                };

                this.$store.state.treatmentStore.selectedTreatments.map(treatment => {
                    data.treatments.push({id: treatment.treatmentId, name: treatment.treatmentEn});
                });

                this.$store.state.investigationStore.selectedInvestigations.map(investigation => {
                    data.investigations.push({id: investigation.investigationId, name: investigation.investigationEn});
                });

                if (!empty(data.treatments) || !empty(data.investigations)) {
                    console.log(data)
                    await this.$store.dispatch('appointments/update', {
                        url: `/appointments/${this.$route.params.appointmentId}`,
                        data,
                        showAlert: true
                    });
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped>
.treatment-options::-webkit-scrollbar,
.investigation-options::-webkit-scrollbar {
    width: 5px;
}
.treatment-options,
.investigation-options {
    overflow-y: auto;
    height: 70vh;
    margin-bottom: 30px;
}
.treatment-options::-webkit-scrollbar-thumb,
.investigation-options::-webkit-scrollbar-thumb {
    background-color: #90A4AE ;
    border-radius: 6px;
}
</style>