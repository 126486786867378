<template>
  <div>
    <h4>Symptoms</h4>
    <v-row class="mt-3">
      <v-col
        cols="12"
        lg="12"
        md="12"
      >
        <Multiselect
          v-model="items"
          :options="$store.state.symptomsStore.allSymptoms"
          :searchable="true"
          :close-on-select="true"
          :hide-selected="true"
          :show-labels="false"
          :multiple="true"
          :clear-on-select="false"
          :preserve-search="true"
          :options-limit="20"
          placeholder="Search symptoms"
          label="symptom_en"
          track-by="id"
          tag-placeholder="Press enter to create new symptom"
          :taggable="true"
          @tag="addNewSymptom"
          @select="setSelectedValue"
          @remove="removeSelected"
        >
          <template
            slot="selection"
            slot-scope="{ values, search, isOpen }"
          >
            <span
              v-if="values.length && !isOpen"
              class="multiselect__single"
            >
              {{ values.length > 1 ? values.length + ' symptoms' : values.length + ' symptom' }} selected
            </span>
          </template>
        </Multiselect>
      </v-col>

      <v-col cols="6">
        <ul v-if="items.length">
          <template v-for="(item, index) in items">
            <li
              v-if="index === 0 || isEven(index)"
              :key="`items-list-${index}`"
            >
              <span>{{ index + 1 }}. </span>
              <v-chip
                class="ma-1"
                close
                label
                color="orange"
                text-color="white"
                @click:close="onItemRemove(item, index)"
              >
                {{ item.symptom_en }}
              </v-chip>
            </li>
          </template>
        </ul>
      </v-col>
      <v-col cols="6">
        <ul v-if="items.length">
          <template v-for="(item, index) in items">
            <li
              v-if="index > 0 && isOdd(index)"
              :key="`items-list-${index}`"
            >
              <span>{{ index + 1 }}. </span>
              <v-chip
                class="ma-1"
                close
                label
                color="orange"
                text-color="white"
                @click:close="onItemRemove(item, index)"
              >
                {{ item.symptom_en }}
              </v-chip>
            </li>
          </template>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="12"
      >
        <h4>Diagnostic Reports</h4>
      </v-col>
      <v-col
        cols="12"
        lg="12"
      >
        <div class="image-upload">
          <v-file-input
            multiple
            counter
            small-chips
            outlined
            dense
            hide-details="auto"
            prepend-icon=""
            prepend-inner-icon="mdi-cloud-upload-outline"
            placeholder="Choose images...."
            truncate-length="15"
            @change="onChangeImageUpload"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          dark
          color="orange"
          class="text-capitalize font-weight-bold"
          :loading="predictButtonLoader"
          @click="predictDisease()"
        >
          Predict Disease
          <template #loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-show="$store.state.symptomsStore.matchedDisease.length > 0"
      class="flex-column"
    >
      <v-col cols="12">
        <div
          v-for="(disease, index) in $store.state.symptomsStore.matchedDisease"
          :key="index"
          class="d-flex align-center mb-5"
        >
          <v-radio-group
            v-model="radios"
            class="ma-0"
            hide-details
          >
            <v-radio 
              :value="disease.diseaseId" 
              color="orange"
            >
              <template #label>
                <div>
                  <span>{{ disease.diseaseEn }}</span>
                  <span> - </span>
                  <span>{{ percentage(disease.matchCount, $store.state.symptomsStore.selectedSymptomIds.length) }} %</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            outlined
            color="orange"
            class="text-capitalize font-weight-bold"
            :disabled="!radios"
            @click="selectSymptoms"
          >
            Next <v-icon right>
              mdi-chevron-double-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {empty, fireNotification} from "@/helpers/utility";
import Multiselect from 'vue-multiselect';
import {helpers} from "@/mixins/helpers";

export default {
    name: "DiseasePredictionComponent",
    components: {Multiselect},
    mixins: [helpers],
    props: {
        id: {
            required: true
        },
        appointmentId: {
            required: true
        }
    },
    data() {
        return {
            isPredict: false,
            predictButtonLoader: false,
            items: [],
            radios: '',
            symptoms: [],
            symptomsLeft: [],
            symptomsRight: [],
            search: null,
            loading: false
        }
    },
    watch: {
        radios(val) {
            if (!empty(val)) {
                let item = this.$store.state.symptomsStore.matchedDisease.find(disease => disease.diseaseId === val);
                if (!empty(item)) {
                    this.$store.commit('symptomsStore/SET_SELECTED_DISEASE', item);
                    this.$cookies.set('SELECTED_DISEASE', item);
                }
            }
        }
    },
    created() {
        this.getSymptoms();
    },
    methods: {
        async onChangeImageUpload(files) {
            try {
                if (files.length) {
                    let formData = new FormData();
                    formData.append('appointmentId', this.appointmentId);
                    for (const file of files) {
                        formData.append('reports[]', file);
                    }

                    let response = await this.$store.dispatch('appointments/storeReports', formData);
                    if (response?.data) {
                        fireNotification({
                            text: response.data.Message,
                            color: 'success',
                            action: true
                        });
                    }
                } else {
                    throw "No file selected";
                }
            } catch (e) {
                console.log(e)
            }

        },
        setSelectedValue(selectedOption) {
            this.$store.commit('symptomsStore/SET_SELECTED_SYMPTOM_IDS', selectedOption.id);
        },
        removeSelected(removedOption) {
            this.$store.commit('symptomsStore/REMOVE_SELECTED_SYMPTOM_IDS', removedOption.id);
        },
        async predictDisease() {
            try {
                this.isPredict = true
                this.predictButtonLoader = true;
                if (empty(this.$store.state.symptomsStore.selectedSymptomIds)) {
                    fireNotification({
                        text: 'Please select at least one symptom',
                        color: 'error',
                        action: true
                    });

                    setTimeout(() => {
                        this.predictButtonLoader = false;
                    }, 250);

                    return '';
                }
                let formData = new FormData();
                for (const id of this.$store.state.symptomsStore.selectedSymptomIds) {
                    formData.append('symptoms[]', id);
                }

                let response = await this.$store.dispatch('symptomsStore/diseasePrediction', formData);
                if (response?.data) {
                    this.predictButtonLoader = false;
                    let symptoms = [];
                    for (let data of this.items) {
                        this.$store.commit('symptomsStore/SET_SELECTED_SYMPTOM_IDS', data.id);
                        symptoms.push({
                            id: data.id,
                            symptom_en: data.symptom_en,
                            status: data.status
                        });
                    }

                    if (!empty(symptoms)) {
                        let data = {symptoms};
                        await this.$store.dispatch('appointments/update', {
                            url: `/appointments/${this.$route.params.appointmentId}`,
                            data,
                            showAlert: true
                        });
                        this.$store.commit('symptomsStore/SET_SELECTED_SYMPTOMS', JSON.stringify(symptoms));
                    }
                }
            } catch (e) {
                this.predictButtonLoader = false;
                console.log(e)
            }
        },
        selectSymptoms() {
            this.$parent.step++;
            this.$router.replace({ path: window.location.pathname, query:{ step: '2'} });
        },
        async getSymptoms() {
            try {
                await this.$store.dispatch('symptomsStore/symptoms');
                this.items = this.$store.state.symptomsStore.selectedSymptoms;
            } catch (e) {
                console.log(e)
            }
        },
        onItemRemove(selectedOption, key) {
            this.removeSelected(selectedOption);
            this.items.splice(key, 1);
        },
        async addNewSymptom(queryText) {
            try {
                let data = await this.$store.dispatch('symptomsStore/store', { "symptom_en" : queryText});
                this.symptoms.push(data);
                this.items.push(data);
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .multiselect__content {
        padding-left: 0 !important;
    }

    .multiselect__option--highlight,
    .multiselect__tag,
    .multiselect__tag-icon:hover,
    .multiselect__tag-icon:focus {
        background: orange !important;
    }

    .multiselect__tag-icon:after {
        color: #fff !important;
    }

    .multiselect__option--highlight:after {
        background: #186ab7 !important;
    }
    .multiselect__tags {
        border: 1px solid #9e9e9e !important;
    }
    .multiselect__tags-wrap {
        display: none !important;
    }
    ul {
        padding: 0 !important;
        list-style-type: none !important;
    }
</style>