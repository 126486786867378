<template>
  <v-container class="left-aligned-container">
    <v-row>
      <v-col
        cols="12"
        md="5"
      >
        <PatientDetails
          ref="patientDetails"
          :patient-id="id"
          :appointment-id="appointmentId"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <template v-if="step === 1">
          <keep-alive>
            <DiseasePredictionComponent
              :id="id"
              :appointment-id="appointmentId"
            />
          </keep-alive>
        </template>
        <template v-if="step === 2">
          <keep-alive>
            <InvestigationComponent ref="investigation" />
          </keep-alive>
        </template>
        <template v-if="step === 3">
          <keep-alive>
            <Prescription ref="prescription" />
          </keep-alive>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientDetails from "@/components/PatientDetails";
import DiseasePredictionComponent from "@/components/DiseasePredictionComponent";
import file from "@/assets/images/Cloud.png";
import InvestigationComponent from "@/components/InvestigationComponent";
import Prescription from "@/components/PrescriptionComponent.vue";
import { integer } from "vee-validate/dist/rules";

export default {
    name: "PatientCaseHistory",
    components: {Prescription, InvestigationComponent, PatientDetails, DiseasePredictionComponent},
    props: {
        id: {
          type: integer,
          required: true,
        },
        appointmentId: {
          type: integer,
          required: true
        }
    },
    data: () => ({
        file: file,
        selectedDisease: '',
        step: 1
    }),
    created() {
        this.step = this.$route.query ? parseInt(this.$route.query.step) : 1;
    }
}
</script>

<style scoped lang="scss">
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    .left-aligned-container {
      margin: 10px;
      margin-top: 1px ;
   }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
      }
</style>