<template>
  <v-card
    v-if="$store.state.caseHistoryStore.data"
  >
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="130"
      >
        <v-img
          width="100%"
          :src="patient"
          style="margin: 0 0 auto;"
          aspect-ratio="2"
          contain
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          {{ $store.state.caseHistoryStore.data.name }}
        </v-list-item-title>
        <ul>
          <li>Age - {{ calculateAge($store.state.caseHistoryStore.data.dob) }}</li>
          <li v-if="$store.state.caseHistoryStore.data.gender">
            Sex - {{ $store.state.caseHistoryStore.data.gender }}
          </li>
          <li v-if="$store.state.caseHistoryStore.data.nid">
            NID - {{ $store.state.caseHistoryStore.data.nid }}
          </li>
          <li v-if="$store.state.caseHistoryStore.data.phone_no">
            Phone - {{ $store.state.caseHistoryStore.data.phone_no }}
          </li>
        </ul>
      </v-list-item-content>
    </v-list-item>
    <div>
      <v-card-title class="font-size-15">
        Case History
      </v-card-title>
      <div class="mb-6 pl-2 case-histories">
        <p class="font-size-14 pl-10"> 
          {{ moment($store.state.caseHistoryStore.data.diagnosed_at).format("DD MMMM Y") }}, {{ $store.state.caseHistoryStore.data.disease }}
        </p>
        <p>{{ $store.state.caseHistoryStore.data.medications }}</p>
        <p>{{ $store.state.caseHistoryStore.data.doctor }}</p>
      </div>
    </div>

    <div>
      <v-card-title class="font-size-15">
        Previous History
      </v-card-title>
      <div class="pl-2">
        <span>{{ $store.state.caseHistoryStore.data.other }}</span>
      </div>
    </div>

    <div>
      <v-card-title class="font-size-15">
        Physical Investigation
      </v-card-title>
      <v-row class="p-tb-0-lr-12">
        <v-col>
          <div>
            <label class="font-size-12">Height</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.height.ft"
                outlined
                dense
                hide-details="auto"
                label="feet"
                required
                color="orange"
              />
              <section 
                class="p-r-2" 
              />
              <v-text-field
                v-model="physicalInvestigation.height.in"
                outlined
                dense
                hide-details="auto"
                label="inch"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>

        <v-col>
          <div>
            <label class="font-size-12">Temperature</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.temperature.value"
                outlined
                dense
                hide-details="auto"
                label="F"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>
      </v-row>
      <v-row class="p-tb-0-lr-12">
        <v-col>
          <div>
            <label class="font-size-12">Weight</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.weight.value"
                outlined
                dense
                hide-details="auto"
                label="KG"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>
        <v-col>
          <div>
            <label class="font-size-12">BP (mmHG)</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.bp.sys"
                outlined
                dense
                hide-details="auto"
                label="SYS"
                required
                color="orange"
              />
              <section class="p-r-2" /> 
              <v-text-field
                v-model="physicalInvestigation.bp.dia"
                outlined
                dense
                hide-details="auto"
                label="DIA"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>
      </v-row>
      <v-row class="p-tb-0-lr-12">
        <v-col>
          <div>
            <label class="font-size-12">Heart Rate</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.heartRate.value"
                outlined
                dense
                hide-details="auto"
                label="BPM"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>
        <v-col>
          <div>
            <label class="font-size-12">Blood Oxygen Level</label>
            <section class="d-flex">
              <v-text-field
                v-model="physicalInvestigation.bloodOxygen.value"
                outlined
                dense
                hide-details="auto"
                label="%"
                required
                color="orange"
              />
            </section>
          </div>
        </v-col>
      </v-row>
      <v-row class="p-tb-0-lr-12">
        <v-col>
          <v-btn
            color="orange"
            dark
            @click.prevent="appointmentDataStore"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="$store.state.symptomsStore.selectedSymptoms">
      <v-card-title class="font-weight-bold">
        Symptoms
      </v-card-title>
      <v-row class="p-tb-0-lr-12">
        <v-col class="font-size-15">
          <v-chip
            v-for="symptom of $store.state.symptomsStore.selectedSymptoms"
            :key="`selected-symptom-${symptom.id}`"
            small
            dark
            class="mr-2 mb-2"
            color="orange"
          >
            {{ symptom.symptom_en }}
          </v-chip>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import patient from "@/assets/images/paitent.png";
import {empty} from "@/helpers/utility";
import {helpers} from "@/mixins/helpers";

export default {
    name: "PatientDetails",
    mixins: [helpers],
    props: ['patientId', 'appointmentId'],
    data: () => ({
        patient: patient,
        physicalInvestigation: {},
        previousHistory: ['Acute Cholecystitis', 'Appendicitis', 'Type 2 Diabetes', 'Rx - Cholecystectomy', 'Appendectomy', 'Emazid M 5/500']
    }),
    created() {
        this.physicalInvestigation = this.$store.state.appointments.physicalInvestigation;
        this.getPatientCaseHistory();
        this.getAppointmentDetails();
    },
    methods: {
        async getPatientCaseHistory() {
            try {
                await this.$store.dispatch('caseHistoryStore/get', this.patientId);
                this.cardLoader = false;
            } catch (e) {
                console.log(e)
            }
        },
        async getAppointmentDetails() {
            try {
                let response = await this.$store.dispatch('appointments/details', this.appointmentId);
                this.physicalInvestigation = this.$store.state.appointments.physicalInvestigation;
                this.$store.commit('symptomsStore/SET_SELECTED_SYMPTOMS', response.data.symptoms);
                for (let item of response.data.symptoms) {
                    this.$store.commit('symptomsStore/SET_SELECTED_SYMPTOM_IDS', item.id);
                }
            } catch (e) {
                console.log(e)
            }
        },
        async appointmentDataStore() {
            try {
                let data = {
                    physicalInvestigations: await this.$store.dispatch('appointments/getPhysicalInvestigationFormData')
                };

                if (!empty(data.physicalInvestigations)) {
                    this.$store.state.prescriptionStore.physicalInvestigations = data.physicalInvestigations;
                    await this.$store.dispatch('appointments/update', {
                        url: `/appointments/${this.$route.params.appointmentId}`,
                        data,
                        showAlert: true
                    });
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding-bottom: 5px;
    }
}

p {
    margin: 0;
}

.case-histories {
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.investigation-input {
    width: 100%;
    height: 28px;
    border: 2px solid #3B7CB9;
    margin: 0 5px;
    border-radius: 4px;
    text-align: center;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 12px !important;
}
.p-tb-0-lr-12 {
    padding: 0 12px;
}
</style>