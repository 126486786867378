import {empty} from "@/helpers/utility";
import moment from "moment";

export const helpers = {
    methods: {
        percentage(partialValue, totalValue) {
            if (!empty(totalValue) || !empty(partialValue)) return (0).toFixed(2);

            return ((100 * partialValue) / totalValue).toFixed(2);
        },
        calculateAge(dob) {
            if (!dob) return 0;
            return Math.floor(moment(moment(new Date()).format('YYYY-MM-DD')).diff(moment(dob, "YYYY-MM-DD"), 'years'));
        },
        isOdd(number) {
            return parseInt(number) % 2 !== 0;
        },
        isEven(number) {
            return parseInt(number) % 2 === 0;
        }
    }
}