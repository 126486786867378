<template>
  <div>
    <v-btn
      dark
      small
      ripple
      elevation="7"
      color="secondary"
      @click="goBack"
    >
      <v-icon left>
        mdi-arrow-left-thin
      </v-icon> Back
    </v-btn>

    <v-card-title class="font-weight-bold pl-0 pb-0 pr-0">
      Diagnosed Disease
    </v-card-title>

    <v-container
      class="pr-0 pb-0 pt-0"
      fluid
    >
      <v-row>
        <v-col
          class="d-flex align-center pl-0"
          cols="6"
        >
          <span class="font-size-15">
            <v-icon name="alert-icon">mdi-alert-circle-outline</v-icon> {{ $store.state.symptomsStore.selectedDisease.diseaseEn }}
          </span>
        </v-col>
        <v-col
          class="d-flex flex-column"
          cols="6"
        >
          <v-btn
            outlined
            dark
            small
            ripple
            style="height: 25%; width: 50%; padding: 18px;"
            elevation="7"
            color="orange"
            @click.prevent="updateAppointment(true)"
          >
            <v-icon left>
              mdi-printer
            </v-icon> Print
          </v-btn>
          <section class="mt-2" />
          <v-btn
            dark
            small
            ripple
            style="height: 25%; width: 50%; padding: 18px;"
            elevation="7"
            color="orange mb-3"
            @click.prevent="updateAppointment(false)"
          >
            <v-icon left>
              mdi-checkbox-marked-circle
            </v-icon> Complete
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="6">
          <v-card-title class="font-weight-bold pl-0">
            Investigations
          </v-card-title>
          <div class="investigation-options">
            <ol v-if="$store.state.investigationStore.selectedInvestigations">
              <li
                v-for="(investigation, index) in $store.state.investigationStore.selectedInvestigations"
                :key="`investigation-list-${index}`"
                style="font-size: 15px;"
              >
                {{ investigation.investigationEn }}
              </li>
            </ol>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-card-title class="font-weight-bold pl-0">
            RX
          </v-card-title>
          <div class="medicine-search-wrapper">
            <Multiselect
              v-model="selectedMedicines"
              :options="$store.state.treatmentStore.allTreatments"
              :searchable="true"
              :close-on-select="true"
              :hide-selected="true"
              :show-labels="false"
              :multiple="true"
              :clear-on-select="false"
              :preserve-search="true"
              :options-limit="20"
              placeholder="Search Medicine"
              label="treatmentEn"
              track-by="id"
              @select="setSelectedValue"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  v-if="values.length && !isOpen"
                  class="multiselect__single"
                >
                  {{ values.length > 1 ? values.length + ' medicines' : values.length + ' medicine' }} selected
                </span>
              </template>
            </Multiselect>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="treatment-options">
            <ol v-if="$store.state.treatmentStore.selectedTreatments.length">
              <li
                v-for="(treatment, key) in $store.state.treatmentStore.selectedTreatments"
                :key="`treatment-list-${key}`"
                style="font-size: 15px;"
              >
                {{ treatment.treatmentEn }} <v-icon
                  class="red--text cursor-pointer"
                  style="font-size: 15px;"
                  title="Remove"
                  @click="removeSelected(treatment.treatmentId)"
                >
                  mdi-delete
                </v-icon>
                <v-row class="mt-1 mb-1">
                  <v-col
                    cols="3"
                    class="pl-0 pr-0"
                  >
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="medicineSchedules.dose[key]"
                        outlined
                        dense
                        hide-details="auto"
                        label="Dose"
                        placeholder="example: 1+0+1"
                        required
                        color="orange"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pr-0"
                  >
                    <v-text-field
                      v-model="medicineSchedules.days[key]"
                      outlined
                      dense
                      hide-details="auto"
                      label="Days / continue"
                      required
                      color="orange"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    class="pr-0"
                  >
                    <v-select
                      v-model="medicineSchedules.application_time[key]"
                      :items="['Before Meal', 'After Meal']"
                      label="Before / After Meal"
                      dense
                      outlined
                      hide-details="auto"
                      color="orange"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="medicineSchedules.type[key]"
                      :items="['tablet', 'capsule', 'syrup', 'injection', 'suppository']"
                      label="Choose Type"
                      dense
                      outlined
                      hide-details="auto"
                      color="orange"
                    />
                  </v-col>
                </v-row>
              </li>
            </ol>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-card-title class="font-weight-bold pl-0">
                Continue medicine for
              </v-card-title>
              <v-text-field
                v-model="medicineSchedules.continue_medicine"
                outlined
                dense
                placeholder="Enter comma seperated data"
                hide-details="auto"
                required
                color="orange"
              />
            </v-col>
            <v-col cols="6">
              <v-card-title class="font-weight-bold pl-0">
                Next Appointment after
              </v-card-title>
              <v-text-field
                v-model="medicineSchedules.next_appointment_after"
                outlined
                dense
                hide-details="auto"
                label="Days"
                required
                color="orange"
              />
            </v-col>
            <v-col cols="6">
              <v-card-title class="font-weight-bold pl-0">
                Refer to a doctor
              </v-card-title>
              <v-text-field
                v-model="medicineSchedules.referral_doctor_name"
                outlined
                dense
                hide-details="auto"
                label="name"
                required
                color="orange"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {empty, fireNotification, getPrescriptionHtml, printOrSavePdf} from "@/helpers/utility";
import VueCookies from "vue-cookies";

export default {
    name: "Prescription",
    components: {Multiselect},
    data() {
        return {
            medicines: [],
            selectedMedicines: [],
            medicineSchedules: {
                dose: [],
                days: [],
                type: [],
                application_time: [],
                continue_medicine: '',
                next_appointment_after: '',
                referral_doctor_name: ''
            }
        }
    },
    created() {
        if (empty(this.$store.state.symptomsStore.selectedDisease)) {
            this.$store.commit('symptomsStore/SET_SELECTED_DISEASE', this.$cookies.get('SELECTED_DISEASE'));
        }
        if (empty(this.$store.state.treatmentStore.selectedTreatments)) {
            this.$store.commit('treatmentStore/SET_SELECTED_TREATMENTS', JSON.parse(this.$cookies.get('SELECTED_TREATMENTS')));
            this.$store.state.treatmentStore.selectedTreatments.map(medicine => {
                this.selectedMedicines.push({id: medicine.treatmentId, treatmentEn: medicine.treatmentEn});
            })
        }
        if (empty(this.$store.state.investigationStore.selectedInvestigations)) {
            this.$store.commit('investigationStore/SET_SELECTED_INVESTIGATIONS', JSON.parse(this.$cookies.get('SELECTED_INVESTIGATIONS')));
        }

        this.getMedicines();
    },
    methods: {
        goBack() {
            let current = this.$route;
            let route = {
                name: current.name,
                params: current.params,
                query: {step: 2}
            };
            this.$parent.step--;
            this.$router.push(route);
        },
        setSelectedValue(selectedOption) {
            this.$store.commit('treatmentStore/SET_ONE_SELECTED_TREATMENT', {treatmentId: selectedOption.id, treatmentEn: selectedOption.treatmentEn, treatmentBn: null});
            this.$cookies.set('SELECTED_TREATMENTS', JSON.stringify(this.$store.state.treatmentStore.selectedTreatments));
        },
        removeSelected(id) {
            if (confirm('Are you sure to remove this?')) {
                this.$store.commit('treatmentStore/REMOVE_ONE_SELECTED_TREATMENT', id);

                this.selectedMedicines = this.selectedMedicines.filter(function (item) {
                    return item.id !== id;
                });

                this.$cookies.set('SELECTED_TREATMENTS', JSON.stringify(this.$store.state.treatmentStore.selectedTreatments));
            }
        },
        async getMedicines() {
            if (empty(this.$store.state.treatmentStore.allTreatments)) {
                await this.$store.dispatch('treatmentStore/list');
            }
        },
        async updateAppointment(print) {
            if (this.validateAppointment()) {
                return;
            }

            let data = this.getPrescriptionData();

            if (!print) {
                data.status = 'done';
            }

            await this.$store.dispatch('appointments/update', {
                url: `/appointments/${this.$route.params.appointmentId}`,
                data,
                showAlert: true
            });

            if (print) {
                printOrSavePdf(
                    getPrescriptionHtml({
                        doctor: this.$store.state.profileStore.doctorProfile,
                        patient: this.$store.state.caseHistoryStore.data,
                        medicines: data?.prescription?.medications,
                        continueMedicine: data?.prescription?.continueMedicine,
                        nextAppointment: data?.prescription?.nextAppointment
                    })
                );
            } else {
                let keys = VueCookies.keys();
                if (!empty(keys)) {
                    for (const key of keys) {
                        if (key !== 'auth') {
                            VueCookies.remove(key);
                        }
                    }
                }
                this.$router.push('ConfirmAppointments');
            }
        },
        validateAppointment() {
            if (!this.medicineSchedules.dose.length) {
                fireNotification({
                    text: 'please enter valid dose information',
                    color: 'error',
                    action: true
                });
                return true;
            }
            if (!this.medicineSchedules.days.length) {
                fireNotification({
                    text: 'please enter valid days information',
                    color: 'error',
                    action: true
                });
                return true;
            }
            if (!this.medicineSchedules.type.length) {
                fireNotification({
                    text: 'please enter valid medicine type information',
                    color: 'error',
                    action: true
                });
                return true;
            }
            if (!this.medicineSchedules.application_time.length) {
                fireNotification({
                    text: 'please enter valid medicine application time information',
                    color: 'error',
                    action: true
                });
                return true;
            }
            return false;
        },
        getPrescriptionData() {
            let data = {
                prescription: {
                    "medications": [],
                    "continueMedicine": this.medicineSchedules.continue_medicine.split(',').filter((item) => item.trim()),
                    "nextAppointment": {
                        "unit": "days",
                        "value": this.medicineSchedules.next_appointment_after
                    },
                    "referredTo": {
                        // "docId": 31,
                        "name": this.medicineSchedules.referral_doctor_name
                    }
                }
            };

            for (let key in this.$store.state.treatmentStore.selectedTreatments) {
                data.prescription.medications.push({
                    "name": this.$store.state.treatmentStore.selectedTreatments[key]?.treatmentEn,
                    "dosage": this.medicineSchedules.dose[key],
                    "duration": {
                        "interval": "daily",
                        "value": this.medicineSchedules.days[key]
                    },
                    "application": {
                        "intake": this.medicineSchedules.type[key],
                        "time": this.medicineSchedules.application_time[key]
                    },
                });
            }

            return data;
        }
    }
}
</script>

<style scoped>

</style>